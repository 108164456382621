/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const chart = {
    chartSalesVsYearlyResults: {
        series: [
            {
                name: '2024',
                data: [
                    {
                        x: DateTime.fromISO('2024-01-01').toJSDate(),
                        y: 290960,
                    },
                    {
                        x: DateTime.fromISO('2024-02-01').toJSDate(),
                        y: 171356,
                    },
                    {
                        x: DateTime.fromISO('2024-03-01').toJSDate(),
                        y: 293720,
                    },
                    {
                        x: DateTime.fromISO('2024-04-01').toJSDate(),
                        y: 206352,
                    },
                    {
                        x: DateTime.fromISO('2024-05-01').toJSDate(),
                        y: 388220,
                    },
                    {
                        x: DateTime.fromISO('2024-06-01').toJSDate(),
                        y: 436847,
                    },
                    {
                        x: DateTime.fromISO('2024-07-01').toJSDate(),
                        y: 424779,
                    },
                    {
                        x: DateTime.fromISO('2024-08-01').toJSDate(),
                        y: 281489,
                    },
                    {
                        x: DateTime.fromISO('2024-09-01').toJSDate(),
                        y: 242807,
                    },
                    {
                        x: DateTime.fromISO('2024-10-01').toJSDate(),
                        y: 391256,
                    },
                    {
                        x: DateTime.fromISO('2024-12-01').toJSDate(),
                        y: 124941,
                    },
                ],
            },
            {
                name: '2023',
                data: [
                    {
                        x: DateTime.fromISO('2023-01-01').toJSDate(),
                        y: 106097,
                    },
                    {
                        x: DateTime.fromISO('2023-02-01').toJSDate(),
                        y: 385688,
                    },
                    {
                        x: DateTime.fromISO('2023-03-01').toJSDate(),
                        y: 379830,
                    },
                    {
                        x: DateTime.fromISO('2023-04-01').toJSDate(),
                        y: 188060,
                    },
                    {
                        x: DateTime.fromISO('2023-05-01').toJSDate(),
                        y: 444057,
                    },
                    {
                        x: DateTime.fromISO('2023-06-01').toJSDate(),
                        y: 304842,
                    },
                    {
                        x: DateTime.fromISO('2023-07-01').toJSDate(),
                        y: 176310,
                    },
                    {
                        x: DateTime.fromISO('2023-08-01').toJSDate(),
                        y: 176496,
                    },
                    {
                        x: DateTime.fromISO('2023-09-01').toJSDate(),
                        y: 54442,
                    },
                    {
                        x: DateTime.fromISO('2023-10-01').toJSDate(),
                        y: 382132,
                    },
                    {
                        x: DateTime.fromISO('2023-11-01').toJSDate(),
                        y: 439722,
                    },
                    {
                        x: DateTime.fromISO('2023-12-01').toJSDate(),
                        y: 498664,
                    },
                ],
            },
        ]
    }
};
