/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const companiesDocumentationIndex: FuseNavigationItem = {
    id: 'companies.root',
    title: 'Organizacje',
    icon: 'heroicons_outline:briefcase',
    type: 'collapsable',
    children: [
        {
            id: "159807f7-accf-4c0f-bda4-c5a849e03c71",
            title: "Produkty Zwierzęce S.A.",
            type: 'basic',
            link: '/documentation/159807f7-accf-4c0f-bda4-c5a849e03c71'
        },
        {
            id: "b15b5dd6-db97-4473-9020-1be965dc5db9",
            title: "Kochamy Zwierzęta S.A.",
            type: 'basic',
            link: '/documentation/b15b5dd6-db97-4473-9020-1be965dc5db9'
        },
        {
            id: "9eb39837-5385-4e80-9aa4-1bc943b1fd2f",
            title: "Weterynarz 24h S.A.",
            type: 'basic',
            link: '/documentation/9eb39837-5385-4e80-9aa4-1bc943b1fd2f'
        },
        {
            id: "b0ac6f88-3a72-4fa0-962a-bc01da5ed1fc",
            title: "Małopolska trzoda",
            type: 'basic',
            link: '/documentation/b0ac6f88-3a72-4fa0-962a-bc01da5ed1fc'
        },
        {
            id: "e1848254-ffee-4eb5-b8c0-ae90d413a0a3",
            title: "Agro przemysł",
            type: 'basic',
            link: '/documentation/e1848254-ffee-4eb5-b8c0-ae90d413a0a3'
        }
    ]
}

export const productsDocumnetationIndex: FuseNavigationItem = {
    id: 'products.root',
    title: 'Produkty',
    icon: 'heroicons_outline:document',
    type: 'collapsable',
    children: [
        {
            id: 'sale.products.root',
            title: 'Zakupowe',
            type: 'collapsable', 
            children: [
                {
                    id: "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
                    title: "Mleko Krowie",
                    type: 'basic',
                    link: '/documentation/c474bde6-d54a-4b21-bcf9-a37fd6445abf'
                },
            ]
        },
        {
            id: 'purchase.products.root',
            title: 'Sprzedażowe',
            type: 'collapsable', 
            children: [
                {
                    id: "add41a1a-2a73-4a33-9e00-eec9575033b0",
                    title: "Pasza dla Koni",
                    type: 'basic',
                    link: '/documentation/add41a1a-2a73-4a33-9e00-eec9575033b0'
                },
            ]
        }
    ]
}

export const servicesDocumentationIndex: FuseNavigationItem = {
    id: 'services.root',
        title: 'Serwisy',
        icon: 'heroicons_outline:check-circle',
        type: 'collapsable',
        children: [
            {
                id: 'sale.services.root',
                title: 'Zakupowe',
                type: 'collapsable', 
                children: [
                {
                    id: "1bb02d56-7996-435f-a706-e81abe3a0171",
                    title: "Szczepienie Krów",
                    type: 'basic',
                    link: '/documentation/1bb02d56-7996-435f-a706-e81abe3a0171'
                },
            ]
        },
        {
            id: 'purchase.services.root',
            title: 'Sprzedażowe',
            type: 'collapsable', 
            children: [
                {
                    id: "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
                    title: "Przegląd Weterynaryjny",
                    type: 'basic',
                    link: '/documentation/b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a'
                },
            ]
        }
    ]
}

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'tasks',
        title: 'Zadania',
        subtitle: 'Centrum zarządzania zadaniami',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'tasks.list',
                title: 'Lista zadań',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/dashboards/project',
            },
            {
                id: 'apps.tasks',
                title: 'Tasks',
                type: 'basic',
                icon: 'heroicons_outline:check-circle',
                link: '/dashboards/tasks',
            },
        ]
    },
    {
        id   : 'documentation',
        title: 'Dokumentacja',
        type : 'group',
        subtitle : 'Przeglądaj dokumentacja firmy',
        icon : 'heroicons_outline:clipboard-document-list',
        children: [
            companiesDocumentationIndex,
            productsDocumnetationIndex,
            servicesDocumentationIndex,
            {
                id: 'contacts.root',
                title: 'Kontakty',
                icon: 'heroicons_outline:user',
                type: 'basic',
                link: '/apps/contacts',
            },
            {
                id: 'apps.file-manager',
                title: 'Pliki',
                type: 'basic',
                icon: 'heroicons_outline:cloud',
                link: '/apps/file-manager',
            },
        ]
    },
    {
        id: 'settings',
        title: 'Ustawienia',
        subtitle: 'Zarządzaj swoim uzytkownikiem',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id   : 'settings',
                title: 'Więcej',
                type : 'basic',
                icon : 'heroicons_outline:cog-8-tooth',
                link: '/pages/settings',
                
            }
        ]
    }
    
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dokumentacja',
        type : 'collapsable',
        icon : 'heroicons_outline:clipboard-document-list',
        children: [
            companiesDocumentationIndex,
            productsDocumnetationIndex,
            servicesDocumentationIndex,
            {
                id: 'contacts.root',
                title: 'Kontakty',
                icon: 'heroicons_outline:user',
                type: 'collapsable',
            }
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dokumentacja',
        type : 'collapsable',
        icon : 'heroicons_outline:clipboard-document-list',
        children: [
            companiesDocumentationIndex,
            productsDocumnetationIndex,
            servicesDocumentationIndex,
            {
                id: 'contacts.root',
                title: 'Kontakty',
                icon: 'heroicons_outline:user',
                type: 'collapsable',
            }
        ]
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dokumentacja',
        type : 'collapsable',
        icon : 'heroicons_outline:clipboard-document-list',
        children: [
            companiesDocumentationIndex,
            productsDocumnetationIndex,
            servicesDocumentationIndex,
            {
                id: 'contacts.root',
                title: 'Kontakty',
                icon: 'heroicons_outline:user',
                type: 'collapsable',
            }
        ]
    }
];
