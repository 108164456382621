import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
    companies as companiesData,
    documentations,
} from 'app/mock-api/apps/documentation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class DocumentationMockApi {
    private _companies: any[] = companiesData;
    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Chats - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/apps/documentation/companies').reply(() => {
            // Clone the chats
            const companies = cloneDeep(this._companies);

            // Return the response
            return [200, companies];
        });

        // -----------------------------------------------------------------------------------------------------
        // @ Chat - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/documentation')
            .reply(({ request }) => {
                // Get the chat id
                const id = request.params.get('id');

                // Clone the chats
                const companies = cloneDeep(this._companies);

                let company = documentations.find((item) => item.id === id);
                return [200, company];

                // Find the chat we need
                if(!company)
                     company = companies.find((item) => item.id === id);

                // company["references"] = [
                //     {
                //         name: "Kontakty",
                //         value: company.contactPoint.map(it => ({id: it.id, name: it.name}))
                //     },
                //     {
                //         name: "Produkty sprzedazowe",
                //         value: company.purchaseProduct.map(it => ({id: it.id, name: it.name}))
                //     },
                //     {
                //         name: "Produkty zakupowe",
                //         value: company.saleProducts.map(it => ({id: it.id, name: it.name}))
                //     },
                //     {
                //         name: "Serwisy sprzedazowe",
                //         value: company.purchaseService.map(it => ({id: it.id, name: it.name}))
                //     },
                //     {
                //         name: "Serwisy zakupowe",
                //         value: company.saleServices.map(it => ({id: it.id, name: it.name}))
                //     },
                    
                // ]

                company["references"] = [
                    {
                        name: "Kontakty",
                        value: []
                    },
                    {
                        name: "Produkty sprzedazowe",
                        value: []
                    },
                    {
                        name: "Produkty zakupowe",
                        value: []
                    },
                    {
                        name: "Serwisy sprzedazowe",
                        value: []
                    },
                    {
                        name: "Serwisy zakupowe",
                        value: []
                    },
                    
                ]

                if (!company) {
                    const allContactPoints = companies.flatMap(company => company.contactPoints || []);
                    const contactPoint = allContactPoints.find((item) => item.id === id);

                    if(!contactPoint) {
                        const allPurchaseProducts = companies.flatMap(company => company.purchaseProducts || []);
                        const purchaseProduct = allPurchaseProducts.find((item) => item.id === id);

                        console.log("!!!");
                                console.log(JSON.stringify(allPurchaseProducts));
                                console.log(JSON.stringify(purchaseProduct));

                        if(!purchaseProduct) {
                            const allSalesProducts = companies.flatMap(company => company.saleProducts || []);
                            const salesProduct = allSalesProducts.find((item) => item.id === id);

                            if(!salesProduct) {
                                const allPurchaseServices = companies.flatMap(company => company.purchaseServices || []);
                                const purchaseService = allPurchaseServices.find((item) => item.id === id);

                                

                                if(!purchaseService) {
                                    const allSalesServices = companies.flatMap(company => company.saleServices || []);
                                    const salesService = allSalesServices.find((item) => item.id === id);

                                    if(salesService) {
                                        return [200, salesService];
                                    }

                                } else {
                                    return [200, purchaseService];
                                }

                            } else {
                                return [200, salesProduct];
                            }
                        } else {
                            return [200, purchaseProduct];
                        }
                    } else {
                        return [200, contactPoint];
                    }


                    
                } else {
                    return [200, company];
                }

                // Return the response
                return [404, "Not Found any document"];
            });

       
    }
}
