export const companies = [
  {
    "id": "159807f7-accf-4c0f-bda4-c5a849e03c71",
    "name": "Produkty Zwierzęce S.A.",
    "address": {
      "id": "e3866168-5457-4999-b1de-1f21965ab28e",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "46",
      "zipCode": "28684",
      "country": "Polska"
    },
    "contactPoints": [
      {
        "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
        "name": "Kontakt 22",
        "title": "Menedżer Sprzedaży",
        "phoneNumber": "+48 359-967-272",
        "email": "kontakt22@firma.com",
        "role": "sprzedaż"
      }
    ],
    "saleProducts": [
      {
        "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
        "name": "Mleko Krowie",
        "category": "produkt_zwierzęcy",
        "description": "Produkt wysokiej jakości z 1,5% tłuszczu",
        "price": 100,
        "currency": "PLN",
        "unit": "litr",
        "availabilityStatus": "dostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "purchaseProducts": [
      {
        "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
        "name": "Pasza dla Koni",
        "category": "pasza",
        "description": "Pasza o wysokiej wartości odżywczej dla koni",
        "price": 80,
        "currency": "PLN",
        "unit": "kg",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "saleServices": [
      {
        "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
        "name": "Szczepienie Krów",
        "category": "zdrowie_zwierząt",
        "description": "Coroczne szczepienie krów",
        "price": 100,
        "currency": "PLN",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "purchaseServices": [
      {
        "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
        "name": "Przegląd Weterynaryjny",
        "category": "zdrowie_zwierząt",
        "description": "Rutynowy przegląd zdrowia bydła",
        "price": 120,
        "currency": "PLN",
        "availabilityStatus": "niedostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "b15b5dd6-db97-4473-9020-1be965dc5db9",
    "name": "Kochamy Zwierzęta S.A.",
    "address": {
      "id": "63180294-82b3-4f85-a1dd-1571dc36a28c",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "74",
      "zipCode": "90225",
      "country": "Polska"
    },
    "contactPoints": [
      {
        "id": "131f5d8a-8e68-48c1-8d02-0e6206418435",
        "name": "Kontakt 22",
        "title": "Menedżer Sprzedaży",
        "phoneNumber": "+48 999-324-464",
        "email": "kontakt22@firma.com",
        "role": "sprzedaż"
      }
    ],
    "saleProducts": [
      {
        "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
        "name": "Mleko Krowie",
        "category": "produkt_zwierzęcy",
        "description": "Produkt wysokiej jakości z 1,5% tłuszczu",
        "price": 100,
        "currency": "PLN",
        "unit": "litr",
        "availabilityStatus": "dostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "purchaseProducts": [
      {
        "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
        "name": "Pasza dla Koni",
        "category": "pasza",
        "description": "Pasza o wysokiej wartości odżywczej dla koni",
        "price": 80,
        "currency": "PLN",
        "unit": "kg",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "saleServices": [
      {
        "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
        "name": "Szczepienie Krów",
        "category": "zdrowie_zwierząt",
        "description": "Coroczne szczepienie krów",
        "price": 100,
        "currency": "PLN",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "purchaseServices": [
      {
        "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
        "name": "Przegląd Weterynaryjny",
        "category": "zdrowie_zwierząt",
        "description": "Rutynowy przegląd zdrowia bydła",
        "price": 120,
        "currency": "PLN",
        "availabilityStatus": "niedostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "9eb39837-5385-4e80-9aa4-1bc943b1fd2f",
    "name": "Weterynarz 24h S.A.",
    "address": {
      "id": "336eaa54-5db5-405c-b2d2-b8fddcdb6c14",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "79",
      "zipCode": "59429",
      "country": "Polska"
    },
    "contactPoints": [
      {
        "id": "57092f16-7c27-43d1-94ef-b5cfc8ea715f",
        "name": "Kontakt 22",
        "title": "Menedżer Sprzedaży",
        "phoneNumber": "+48 600-747-119",
        "email": "kontakt22@firma.com",
        "role": "sprzedaż"
      }
    ],
    "saleProducts": [
      {
        "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
        "name": "Mleko Krowie",
        "category": "produkt_zwierzęcy",
        "description": "Produkt wysokiej jakości z 1,5% tłuszczu",
        "price": 100,
        "currency": "PLN",
        "unit": "litr",
        "availabilityStatus": "dostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "purchaseProducts": [
      {
        "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
        "name": "Pasza dla Koni",
        "category": "pasza",
        "description": "Pasza o wysokiej wartości odżywczej dla koni",
        "price": 80,
        "currency": "PLN",
        "unit": "kg",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "saleServices": [
      {
        "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
        "name": "Szczepienie Krów",
        "category": "zdrowie_zwierząt",
        "description": "Coroczne szczepienie krów",
        "price": 100,
        "currency": "PLN",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "purchaseServices": [
      {
        "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
        "name": "Przegląd Weterynaryjny",
        "category": "zdrowie_zwierząt",
        "description": "Rutynowy przegląd zdrowia bydła",
        "price": 120,
        "currency": "PLN",
        "availabilityStatus": "niedostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "b0ac6f88-3a72-4fa0-962a-bc01da5ed1fc",
    "name": "Małopolska trzoda",
    "address": {
      "id": "0397776a-e2f2-4c8c-80d9-a499168792d0",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "18",
      "zipCode": "90615",
      "country": "Polska"
    },
    "contactPoints": [
      {
        "id": "e97c0509-21d6-4c32-9d61-687a42a9f0de",
        "name": "Kontakt 22",
        "title": "Menedżer Sprzedaży",
        "phoneNumber": "+48 548-960-506",
        "email": "kontakt22@firma.com",
        "role": "sprzedaż"
      }
    ],
    "saleProducts": [
      {
        "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
        "name": "Mleko Krowie",
        "category": "produkt_zwierzęcy",
        "description": "Produkt wysokiej jakości z 1,5% tłuszczu",
        "price": 100,
        "currency": "PLN",
        "unit": "litr",
        "availabilityStatus": "dostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "purchaseProducts": [
      {
        "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
        "name": "Pasza dla Koni",
        "category": "pasza",
        "description": "Pasza o wysokiej wartości odżywczej dla koni",
        "price": 80,
        "currency": "PLN",
        "unit": "kg",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "saleServices": [
      {
        "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
        "name": "Szczepienie Krów",
        "category": "zdrowie_zwierząt",
        "description": "Coroczne szczepienie krów",
        "price": 100,
        "currency": "PLN",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "purchaseServices": [
      {
        "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
        "name": "Przegląd Weterynaryjny",
        "category": "zdrowie_zwierząt",
        "description": "Rutynowy przegląd zdrowia bydła",
        "price": 120,
        "currency": "PLN",
        "availabilityStatus": "niedostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "e1848254-ffee-4eb5-b8c0-ae90d413a0a3",
    "companyName": "Agro przemysł",
    "address": {
      "id": "6f417ac5-563a-4cb8-a5ea-a91d44f10bab",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "43",
      "zipCode": "38119",
      "country": "Polska"
    },
    "contactPoints": [
      {
        "id": "a5abc925-11a4-4259-b5ca-5c461a7b5bdc",
        "name": "Kontakt 22",
        "title": "Menedżer Sprzedaży",
        "phoneNumber": "+48 539-828-901",
        "email": "kontakt22@firma.com",
        "role": "sprzedaż"
      }
    ],
    "saleProducts": [
      {
        "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
        "name": "Mleko Krowie",
        "category": "produkt_zwierzęcy",
        "description": "Produkt wysokiej jakości z 1,5% tłuszczu",
        "price": 100,
        "currency": "PLN",
        "unit": "litr",
        "availabilityStatus": "dostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "purchaseProducts": [
      {
        "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
        "name": "Pasza dla Koni",
        "category": "pasza",
        "description": "Pasza o wysokiej wartości odżywczej dla koni",
        "price": 80,
        "currency": "PLN",
        "unit": "kg",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "saleServices": [
      {
        "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
        "name": "Szczepienie Krów",
        "category": "zdrowie_zwierząt",
        "description": "Coroczne szczepienie krów",
        "price": 100,
        "currency": "PLN",
        "availabilityStatus": "dostępny",
        "regionAvailability": "lokalny"
      }
    ],
    "purchaseServices": [
      {
        "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
        "name": "Przegląd Weterynaryjny",
        "category": "zdrowie_zwierząt",
        "description": "Rutynowy przegląd zdrowia bydła",
        "price": 120,
        "currency": "PLN",
        "availabilityStatus": "niedostępny",
        "regionAvailability": "krajowy"
      }
    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
]

export const documentations = [
  {
    "id": "159807f7-accf-4c0f-bda4-c5a849e03c71",
    "name": "Produkty Zwierzęce S.A.",
    "type": "company",
    "references": [{
      "name": "Kontakty",
      "value": [
        {
          "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
          "name": "Kontakt 22",
        }
      ]
    },
    {
      "name": "Produkty sprzedazowe",
      "value": [
        {
          "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
          "name": "Mleko Krowie",
        }
      ]
    },
    {
      "name": "Produkty zakupowe",
      "value": [
        {
          "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
          "name": "Pasza dla Koni",
        }
      ]
    },
    {
      "name": "Serwisy sprzedazowe",
      "value": [
        {
          "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
          "name": "Szczepienie Krów",
        }
      ]
    },
    {
      "name": "Serwisy zakupowe",
      "value": [
        {
          "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
          "name": "Przegląd Weterynaryjny",
        }
      ]
    },

    ],
    "address": {
      "id": "e3866168-5457-4999-b1de-1f21965ab28e",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "46",
      "zipCode": "28684",
      "country": "Polska"
    },
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "b15b5dd6-db97-4473-9020-1be965dc5db9",
    "name": "Kochamy Zwierzęta S.A.",
    "address": {
      "id": "63180294-82b3-4f85-a1dd-1571dc36a28c",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "74",
      "zipCode": "90225",
      "country": "Polska"
    },
    "type": "company",
    "references": [{
      "name": "Kontakty",
      "value": [
        {
          "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
          "name": "Kontakt 22",
        }
      ]
    },
    {
      "name": "Produkty sprzedazowe",
      "value": [
        {
          "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
          "name": "Mleko Krowie",
        }
      ]
    },
    {
      "name": "Produkty zakupowe",
      "value": [
        {
          "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
          "name": "Pasza dla Koni",
        }
      ]
    },
    {
      "name": "Serwisy sprzedazowe",
      "value": [
        {
          "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
          "name": "Szczepienie Krów",
        }
      ]
    },
    {
      "name": "Serwisy zakupowe",
      "value": [
        {
          "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
          "name": "Przegląd Weterynaryjny",
        }
      ]
    },

    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "9eb39837-5385-4e80-9aa4-1bc943b1fd2f",
    "name": "Weterynarz 24h S.A.",
    "address": {
      "id": "336eaa54-5db5-405c-b2d2-b8fddcdb6c14",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "79",
      "zipCode": "59429",
      "country": "Polska"
    },
    "type": "company",
    "references": [{
      "name": "Kontakty",
      "value": [
        {
          "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
          "name": "Kontakt 22",
        }
      ]
    },
    {
      "name": "Produkty sprzedazowe",
      "value": [
        {
          "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
          "name": "Mleko Krowie",
        }
      ]
    },
    {
      "name": "Produkty zakupowe",
      "value": [
        {
          "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
          "name": "Pasza dla Koni",
        }
      ]
    },
    {
      "name": "Serwisy sprzedazowe",
      "value": [
        {
          "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
          "name": "Szczepienie Krów",
        }
      ]
    },
    {
      "name": "Serwisy zakupowe",
      "value": [
        {
          "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
          "name": "Przegląd Weterynaryjny",
        }
      ]
    },

    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "b0ac6f88-3a72-4fa0-962a-bc01da5ed1fc",
    "name": "Małopolska trzoda",
    "address": {
      "id": "0397776a-e2f2-4c8c-80d9-a499168792d0",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "18",
      "zipCode": "90615",
      "country": "Polska"
    },
    "type": "company",
    "references": [{
      "name": "Kontakty",
      "value": [
        {
          "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
          "name": "Kontakt 22",
        }
      ]
    },
    {
      "name": "Produkty sprzedazowe",
      "value": [
        {
          "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
          "name": "Mleko Krowie",
        }
      ]
    },
    {
      "name": "Produkty zakupowe",
      "value": [
        {
          "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
          "name": "Pasza dla Koni",
        }
      ]
    },
    {
      "name": "Serwisy sprzedazowe",
      "value": [
        {
          "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
          "name": "Szczepienie Krów",
        }
      ]
    },
    {
      "name": "Serwisy zakupowe",
      "value": [
        {
          "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
          "name": "Przegląd Weterynaryjny",
        }
      ]
    },

    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  },
  {
    "id": "e1848254-ffee-4eb5-b8c0-ae90d413a0a3",
    "companyName": "Agro przemysł",
    "address": {
      "id": "6f417ac5-563a-4cb8-a5ea-a91d44f10bab",
      "city": "Warszawa",
      "street": "Zielona",
      "number": "43",
      "zipCode": "38119",
      "country": "Polska"
    },
    "type": "company",
    "references": [{
      "name": "Kontakty",
      "value": [
        {
          "id": "846beb07-78a7-4517-86f5-6299be3f8b06",
          "name": "Kontakt 22",
        }
      ]
    },
    {
      "name": "Produkty sprzedazowe",
      "value": [
        {
          "id": "c474bde6-d54a-4b21-bcf9-a37fd6445abf",
          "name": "Mleko Krowie",
        }
      ]
    },
    {
      "name": "Produkty zakupowe",
      "value": [
        {
          "id": "add41a1a-2a73-4a33-9e00-eec9575033b0",
          "name": "Pasza dla Koni",
        }
      ]
    },
    {
      "name": "Serwisy sprzedazowe",
      "value": [
        {
          "id": "1bb02d56-7996-435f-a706-e81abe3a0171",
          "name": "Szczepienie Krów",
        }
      ]
    },
    {
      "name": "Serwisy zakupowe",
      "value": [
        {
          "id": "b653185b-8f8d-4ad1-a1d1-0dfc8bc0b07a",
          "name": "Przegląd Weterynaryjny",
        }
      ]
    },

    ],
    "description": "Firma z długą tradycją i bogatą historią w hodowli zwierząt."
  }
]
